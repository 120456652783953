import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm({ clientSecret, paymentAggregateRootId, setPaymentComplete, translations }) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (!stripe || !clientSecret) return;
    
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setStatus(paymentIntent.status);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(translations.stripeStatus.succeeded);
          setPaymentComplete(true);
          break;
        case "processing":
          setMessage(translations.stripeStatus.processing);
          break;
        case "requires_payment_method":
          setMessage(translations.stripeStatus.requiresPaymentMethod);
          break;
        case "requires_confirmation":
          setMessage(translations.stripeStatus.requiresPaymentMethod);
          break;
        default:
          setMessage(translations.stripeStatus.error);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Redirection url, if necessary
        return_url: `https://pay.qmerit.com/view/${paymentAggregateRootId}`,
        // Found in quick-node, but receipt email might need to be set in payment intent creation
        receipt_email: email
      },
      redirect: 'if_required'
    }).then(result => {
      // Payment error handling
      if (result.error) {
        if (result.error.type === "card_error" || result.error.type === "validation_error") {
          setMessage(result.error.message);
        } else {
          setMessage(translations.stripeStatus.error);
        }
        setIsLoading(false);
      }
      // Successful payment handling
      else if (result.paymentIntent) setPaymentComplete(true);
      // Unknown Stripe error
      else {
        setMessage(translations.stripeStatus.error);
        setIsLoading(false);
      }
    });
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {status === "succeeded" ? (
        <div id="payment-complete">{message}</div>
      ) : (
        <div>
          <LinkAuthenticationElement
            id="link-authentication-element"
            onChange={(e) => setEmail(e.value.email)}
          />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </div>
      )}
    </form>
  );
}