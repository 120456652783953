import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Grid, Header, Table } from 'semantic-ui-react';

import Loader from "../../components/Loader";
import PaymentError from '../PaymentError/PaymentError';
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import PaymentSuccess from '../../components/PaymentSuccess'

import commonStyles from '../../components/DesignPatternV2.0/commonStyles';
import QmeritWordmarkWhite from '../../images/Qmerit-Wordmark-White.png';
import './PaymentPage.scss';

const {
  Header: QmeritHeader,
  QmeritLogo,
  Page
} = commonStyles;

export default function PaymentPage() {
  const params = useParams();

  const [stripe, setStripe] = useState(null);
  const [translations, setTranslations] = useState(null);
  const [error, setError] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentComplete, setPaymentComplete] = useState(false);

  const getPaymentByAggregateId = async (paymentAggregateId) => {
    let parameters = {
      method: "GET",
      headers: {
        'accept-language': navigator.language
      }
    };

    fetch(`/get-payment/${paymentAggregateId}`, parameters)
    .then(response => {
      return response.json();
    })
    .then(paymentJson => {
      setTranslations(paymentJson?.translations);
      // Invoice redirect (not currently in use)
      if (paymentJson?.hostedInvoiceUrl) window.location.href = paymentJson.hostedInvoiceUrl;
      // Payment intent display
      else if (paymentJson?.clientSecret) {
        setPaymentData(paymentJson?.paymentData);
        setStripe(loadStripe(paymentJson?.stripePublicKey, {stripeAccount: paymentJson?.accountId}));
        setClientSecret(paymentJson?.clientSecret);
      }
      // No data found
      else throw new Error("Error getting payment");
    })
    .catch(error => {
      console.log('error', error);
      setError(true);
    });
  };

  useEffect(() => {
    if (params?.id === undefined) setError(true);
    else getPaymentByAggregateId(params.id);
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      fontFamily: 'Lato-Regular, sans-serif',
      fontSizeBase: '14px',
      colorText: "rgb(62, 65, 72)",
      colorTextPlaceholder: 'rgba(62, 65, 72, 0.35)',
      spacingGridRow: "16px",
      borderRadius: "4px"
    }
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (paymentComplete || paymentData?.status === 'Completed') {
    if (paymentData?.redirectUrlSuccess) window.location.href = paymentData.redirectUrlSuccess;
    else return <PaymentSuccess translations={translations} />;
  }

  if (paymentData?.status === 'Canceled') {
    if (paymentData?.redirectUrlCancel) window.location.href = paymentData.redirectUrlCancel;
    else return <PaymentError translations={translations} />;
  }

  if (error) return <PaymentError translations={translations} />;

  if (stripe && clientSecret && paymentData?.status === 'Created') return (
    <div>
      <QmeritHeader>
        <QmeritLogo src={QmeritWordmarkWhite} />
      </QmeritHeader>
      <Page>
        <Grid stackable id='payment-grid'>
            <Grid.Column width={9} verticalAlign='middle' id='table-column'>
              <Table unstackable basic='very' id='line-item-table'>
                <Table.Header>
                  <Table.HeaderCell textAlign='left'>{translations.stripePayment.headerItemColumn}</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>{translations.stripePayment.headerQuantityColumn}</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>{translations.stripePayment.headerPriceColumn}</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>{translations.stripePayment.headerTotalColumn}</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {paymentData?.items.map(item => (
                    <Table.Row>
                      <Table.Cell textAlign='left'>{item.description}</Table.Cell>
                      <Table.Cell textAlign='right'>{item.quantity}</Table.Cell>
                      <Table.Cell textAlign='right'>{item.amount}</Table.Cell>
                      <Table.Cell textAlign='right'>{item.total}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Footer>
                  {paymentData?.subtotal !== paymentData?.total && (
                    <>
                      <Table.Row>
                        <Table.Cell textAlign='left'></Table.Cell>
                        <Table.Cell textAlign='right'></Table.Cell>
                        <Table.Cell textAlign='right'>{translations.stripePayment.footerSubtotal}</Table.Cell>
                        <Table.Cell textAlign='right' className='total-details'>{paymentData?.subtotal}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell textAlign='left'></Table.Cell>
                        <Table.Cell textAlign='right'></Table.Cell>
                        <Table.Cell textAlign='right'>{translations.stripePayment.footerTax}</Table.Cell>
                        <Table.Cell textAlign='right' className='total-details'>{paymentData?.tax}</Table.Cell>
                      </Table.Row>
                    </>
                  )}
                  <Table.Row className='total'>
                    <Table.Cell textAlign='left'></Table.Cell>
                    <Table.Cell textAlign='right'></Table.Cell>
                    <Table.Cell textAlign='right' style={{paddingRight: '11px'}}>{translations.stripePayment.footerTotal}</Table.Cell>
                    <Table.Cell textAlign='right'>{paymentData?.total}</Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
            <Grid.Column width={7} verticalAlign='middle' id='payment-column'>
              <Elements options={options} stripe={stripe}>
                <CheckoutForm 
                  clientSecret={clientSecret}
                  paymentAggregateRootId={params.id}
                  setPaymentComplete={setPaymentComplete}
                  translations={translations}
                />
              </Elements>
            </Grid.Column>
        </Grid>
      </Page>
    </div>
  );

  return (<Loader color={"#000000"} isLoading={true} />);
};
